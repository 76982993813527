<template>
  <div>
    <div style="height: auto">
      <iframe :src="pg" style="border: 10px solid black" width="500" height="500" frameborder="400" />
    </div>
  </div>
</template>
<script>
import axios from "@axios";

export default {
  data() {
    return {
      pg: null,
    };
  },
  created() {
    axios.get("/pg").then((res) => {
      console.log(res.data);
      this.pg = res.data.paymentPageUrl + "&iframe=true";
    });
  },
};
</script>
